import { createContext, useContext, useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  signInWithPopup
} from "firebase/auth";

import { updateProfile } from "firebase/auth";

import { auth, provider } from "../config/firebase";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  function register(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function updateUserProfile(user, profile) {
    return updateProfile(user, profile);
  }

  function logout() {
    return signOut(auth);
  }

  function passwordReset(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function signInWithGoogle() {
    return signInWithPopup(auth, provider);
  }

  useEffect(() => {
    setLoading(true);
    const unsubscribe = auth.onIdTokenChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    updateUserProfile,
    register,
    passwordReset,
    logout,
    signInWithGoogle,
    error,
    auth,
    setCurrentUser,
    setError,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}