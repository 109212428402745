import {React} from 'react';
import { slide as Menu } from 'react-burger-menu';
import './sideBar.css';

const sideBar = ({
  //here's where I pass the props from App.js
    outerContainerId,
    pageWrapId,
  }) => {
  return (
    <Menu pageWrapId={pageWrapId} outerContainerId={outerContainerId}>
      <a className="menu-item" href="/">
        Home
      </a>
      <a className="menu-item" href="/aboutUs">
        About Us
      </a>
      <a className="menu-item" href="/contactUs">
        Contact Us
      </a>
      <a className="menu-item" href="/location">
        Locations
      </a>
      <a className="menu-item" href="/faq">
        FAQs
      </a>
      <a className="menu-item" href="/renting">
        Renting
      </a>
      <a className="menu-item" href="/media">
        Media
      </a>
      <a className="menu-item" without rel="noreferrer" target="_blank" href='https://www.surveymonkey.com/r/Z5TVSBT'>
        Survey
      </a>
    </Menu>
  );
};

export default sideBar;