import logo from './assets/logo.png';
import Sidebar from './components/sideBar';
import HomeScreen from './components/HomeScreen';
import Media from './components/Media';
import AboutUs from './components/AboutUs';
import LoginScreen from './components/LoginScreen';
import Register from './components/RegisterScreen';
import ForgotPassword from './components/ForgotPassword';
import FAQs from './components/FAQs';
import Footer from './components/Footer';
import ContactUs from './components/ContactUs';
import LoginMenu from './components/LoginMenu';
import Renting from './components/Renting';
import TermsAndConditions from './components/TermsAndConditions';
import Locations from './components/Locations';
import ErrorMessage from './components/ErrorMessage';
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'react-alice-carousel/lib/alice-carousel.css';
import React, { Component } from 'react';
import {
  Box, Flex
} from 'rebass';
import './App.css';
import Profile from './components/Profile';
import WithPrivateRoute from './utils/WithPrivateRoute';

class App extends Component {
  render() {
    return (
      <div className="App" id="outer-container">
        <AuthProvider>
          <Router>
            <Box
              sx={{
                display: 'grid',
                minHeight: '100vh',
                gridTemplateAreas: [
                  '"header" "main" "ads" "footer"',
                  '"header header header" "main" "footer footer footer"'
                ],
                gridTemplateColumns: [
                  '1fr',
                  '100%'
                ],
                gridTemplateRows: [
                  'min-content min-content 1fr min-content min-content',
                  'min-content 1fr min-content'
                ]
              }}>
              <Box
                bg="rgb(247 247 247 / 63%)"
                sx={{
                  gridArea: 'header'
                }}>
                <div className='border'>
                  <Flex>
                    <Box width={1 / 2} px={2} mx={-2}>
                      <Sidebar p={1} outerContainerId={'outer-container'} pageWrapId={'page-wrap'} />
                      <a href="/"><img p={1} src={logo} alt="logo" className='App-logo' /></a>
                    </Box>
                    <Box width={1 / 2} px={2}>
                      <div p={1} className="goRight"><LoginMenu /></div>
                    </Box>
                  </Flex>
                </div>
              </Box>
              <Box
                sx={{
                  gridArea: 'main'
                }}>
                <div id="page-wrap">
                  <ErrorMessage />
                  <Routes>
                    <Route exact path="/" element={<HomeScreen />} />
                    <Route path="/contactUs" element={<ContactUs />} />
                    <Route path="/media" element={<Media />} />
                    <Route path="/aboutUs" element={<AboutUs />} />
                    <Route path="/renting" element={<Renting />} />
                    <Route path="/location" element={<Locations />} />
                    <Route path="/login" element={<LoginScreen />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/forgotPassword" element={<ForgotPassword />} />
                    <Route path="/faq" element={<FAQs />} />
                    <Route path="/termsAndConditions" element={<TermsAndConditions />} />
                    <Route path="/MyProfile" element={
                      <WithPrivateRoute>
                        <Profile />
                      </WithPrivateRoute>
                    } />
                  </Routes>
                </div>
              </Box>
              <Box
                sx={{
                  gridArea: 'footer'
                }}>
                <Footer />
              </Box>
            </Box>
          </Router>
        </AuthProvider>
      </div>
    );
  }
}

export default App;
