import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./FAQs.css";
import PageHeader from './PageHeaders';
function FAQs() {

  return (
    <div>
      <div className="text-center">
        <PageHeader
          title="FAQs"
          subTitle="Frequent Asked Questions">
        </PageHeader>
      </div>
      <div className='faqs'>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography><span className='accordianTitle'>Can I charge my scooter while it's stored?</span></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <span className='accordianSubitle'>In this version of ScootSafe lockers charging is not a feature we offer. Stay tuned for further updates about our eLockers! <a href="/media">Media</a></span>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography><span className='accordianTitle'>How can I get Scootsafe in my local?</span></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <span className='accordianSubitle'>Send us an inquiry via our <a className='link' href="/contactUs">Contact Us</a>  form with your contact details and select "Get scootsafe in your place" option as Reason with as much information as possible in the message and we will come back to you as soon as possible.</span>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography><span className='accordianTitle'>How can I advertise my products on Scootsafe?</span></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <span className='accordianSubitle'>To add your company advertisement to our Scootsafe lockers, please fill in a <a className='link' href="/contactUs">Contact Us</a> form with your contact details and select "Advertise" option on Reason as much information as possible on your organisation and we will come back to you as soon as possible.</span>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography><span className='accordianTitle'>Can I keep any scooters in a Scootsafe locker?</span></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <span className='accordianSubitle'>We cater for all brands of good standing that meet European standards.</span>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default FAQs;