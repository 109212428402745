import { Link } from "react-router-dom";
import { useState } from "react";
import { useAuth } from '../contexts/AuthContext';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleAuthProvider } from "firebase/auth";
import googleIcon from '../assets/googleIcon.png';
import "./RegisterScreen.css";
import PageHeader from './PageHeaders';
function Register() {
    const navigate = useNavigate();
    const [state, setState] = useState({
        email: '',
        password: '',
        confirmPassword: '',
    });

    const { email, password, confirmPassword } = state;
    const handleState = ({ target: { id, value } }) =>
        setState({ ...state, [id]: value });

    const { currentUser, register, setError, signInWithGoogle } = useAuth();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (currentUser) {
            navigate("/");
        }
    }, [currentUser, navigate]);

    async function handleFormSubmit(e) {
        console.log('Called');
        e.preventDefault();

        if (password !== confirmPassword) {
            return setError("Passwords do not match");
        }

        try {
            setLoading(true);
            setError("");
            await register(email, password);
            navigate("/MyProfile");
        } catch (e) {
            if (e.message.includes('email-already-in-use')) {
                setError("Email already in use");
            }
            else if (e.message.includes('auth/invalid-email')) {
                setError("Email is invalid");
            }
            else if (e.message.includes('auth/invalid-password')) {
                setError("Password is invalid");
            }
            else if (e.message.includes('auth/weak-password')) {
                setError("Password should be at least 6 characters");
            } else {
                setError("Failed to register " + e.message);
            }
        }

        setLoading(false);
    }

    async function handleGoogleSignUp() {
        try {
            setError("");
            setLoading(true);
            signInWithGoogle()
                .then((result) => {
                    GoogleAuthProvider.credentialFromResult(result);
                    navigate("/MyProfile");
                }).catch((error) => {
                    const errorMessage = error.message;
                    setError(errorMessage);
                    GoogleAuthProvider.credentialFromError(error);
                });

        } catch (e) {
            if (e.message.includes('wrong-password')) {
                setError("Password or Username is incorrect");
            }
            else if (e.message.includes('user-not-found')) {
                setError("Password or Username is incorrect");
            } else {
                setError("Failed to login " + e.message);
            }
        }

        setLoading(false);
    }
    return (
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <PageHeader
                        title="Register your account"
                        subTitle="">
                    </PageHeader>
                </div>
                <form className="mt-8 space-y-6" onSubmit={handleFormSubmit}>
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <input
                                id={'email'}
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                onChange={handleState}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                                placeholder="Email address"
                            />
                        </div>
                        <br />
                        <div>
                            <input
                                id={'password'}
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                onChange={handleState}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                                placeholder="Password"
                            />
                        </div>
                        <br />
                        <div>
                            <input
                                id={'confirmPassword'}
                                name="confirmPassword"
                                type="password"
                                autoComplete="current-password"
                                required
                                onChange={handleState}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                                placeholder="Confirm Password"
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className=" w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white loginButton"
                            disabled={loading}
                        >
                            Register
                        </button>
                    </div>
                    <div>
                        <div className="text-sm">
                            <button
                                onClick={handleGoogleSignUp}
                                disabled={loading}
                                className=" w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white googleButton"
                            >
                                Sign Up With Google<img alt="googleIcon" src={googleIcon} className="googleIcon"></img>
                            </button>
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="text-sm">
                            <Link
                                to="/login"
                                className="hover:underline underLoginText"
                            >
                                Already have an account? Login
                            </Link>
                            <br />
                            <br />
                            <p className="text-sm underLoginText">By signing up, you agree to Scootsafe's <a without rel="noreferrer" target="_blank" href="https://www.iubenda.com/privacy-policy/78280850" title="Privacy Policy ">Privacy Policy</a> and <a href="/termsAndConditions">Terms of Use</a></p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
export default Register;