import { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { generateAvatar } from "../utils/GenerateAvatar";
import { db } from "../config/firebase";
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
import './Profile.css';
import PageHeader from './PageHeaders';
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Profile() {
  const [username, setUsername] = useState("");
  const [avatars, setAvatars] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState();
  const [loading, setLoading] = useState(false);
  const { currentUser, updateUserProfile, setError } = useAuth();

  let [changeText, setChangeText] = useState(false);

  const handleBrandSelect = selectOption => {
    setSelectedBrands(selectOption);
  };

  const brandItems = [
    "Xiaomi",
    "Segway",
    "Unagi",
    "Levy",
    "Decent",
    "Pure Air",
    "Riley",
    "Glion",
    "Inokim",
    "Hover",
    "Lexgo",
    "Momo",
    "Kaabo",
    "Apollo",
    "Razor",
    "Turboant"];

    const options = brandItems.map(item => ({
      value: item, label: item
    }));

  useEffect(() => {
    const fetchData = () => {
      const res = generateAvatar();
      setAvatars(res);
    };
    fetchData();
    setLoading(true);
    setUsername(currentUser.displayName ? currentUser.displayName : currentUser.providerData.length ? currentUser.providerData[0].displayName ? currentUser.providerData[0].displayName : '' : '');
    const userRef = doc(db, "Users", currentUser.uid);
    try {
      getDoc(userRef).then((result) => {
        if (result.exists()) {
          if(result.data().scooterName){
            console.log('here ' +result.data().scooterName);
            const options = result.data().scooterName.map(item => ({
              value: item, label: item
            }));
            setSelectedBrands(options);
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  }, [currentUser.displayName, currentUser.providerData, currentUser.uid]);


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setChangeText(false);
    if (selectedAvatar === undefined && !currentUser.photoURL) {
      return setError("Please select an avatar");
    }

    if(username === ''){
      return setError("Please set nickname");
    }

    if(selectedBrands.length === 0){
      return setError("Please select your e-scooter");
    }

    try {
      setError("");
      setLoading(true);
      const user = currentUser;
      let profile = {
        displayName: username,
      };
      if (selectedAvatar !== undefined) {
        profile.photoURL = avatars[selectedAvatar];
      }

      await updateUserProfile(user, profile).then(() => {
        currentUser.reload().then(() => {
        });
      }).catch((error) => {
        console.log(error);
      });
      const userRef = doc(db, "Users", user.uid);

      const options = selectedBrands.map(item => item.label);
      const data = {
        scooterName: options,
      };
      setDoc(userRef, data, {merge: true})
        .then(userRef => {
          setLoading(false);
          setChangeText(true);
        })
        .catch(error => {
          setLoading(false);
        });
    } catch (e) {
      setError("Failed to update profile " + e);
      setLoading(false);
    }
  };

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="loader">
        <Oval
          height={80}
          width={80}
          color="#4fa94d"
          wrapperStyle={{}}
          wrapperClass=""
          visible={loading}
          ariaLabel='oval-loading'
          secondaryColor="#4fa94d"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
      <div className="max-w-md w-full space-y-8">
        <div className="text-center">
          <PageHeader
            title="My Profile"
            subTitle="Pick an avatar">
          </PageHeader>
        </div>
        <form className="space-y-6" onSubmit={handleFormSubmit}>
          <div className="flex flex-wrap -m-1 md:-m-2">
            {avatars.map((avatar, index) => (
              <div key={index} className="flex flex -wrap w-1/3">
                <div className="w-full p-1 md:p-2">
                  <img
                    alt="gallery"
                    className={classNames(
                      index === selectedAvatar
                        ? "border-4  border-blue-700 dark:border-blue-700"
                        : "cursor-pointer hover:border-4 hover:border-blue-700",
                      "block object-cover object-center w-36 h-36 rounded-full"
                    )}
                    src={avatar}
                    onClick={() => setSelectedAvatar(index)}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="rounded-md shadow-sm -space-y-px">
            <label htmlFor="username">* Nickname</label>
            <input
              id="username"
              name="username"
              type="text"
              autoComplete="username"
              className="profileFields appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="Nickname"
              label="Nickname"
              disabled={loading}
              defaultValue={currentUser.displayName ? currentUser.displayName : currentUser.providerData.length ? currentUser.providerData[0].displayName ? currentUser.providerData[0].displayName : '' : ''}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <label>* E-Scooter Brands</label>
            <Select
              isMulti
              name="e-scooter"
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleBrandSelect}
              value={selectedBrands}
              styles={{
                option: (provided) => ({
                  ...provided,
                  backgroundColor: selectedBrands?.find(item => item.value === provided.value) ? '#e2eee2' : provided.backgroundColor
                }),
                control: (provided) => ({
                  ...provided,
                  color: '#65A765',
                  backgroundColor: '#fffff'
                })
              }}
            />
          </div>
          <div>
            <button
              type="submit"
              disabled={loading}
              className="submitButton alignButton py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:ring-4 focus:outline-none"
            >
              Update Profile
            </button>
            {changeText ? <p className="successMessage">Profile Updated Sucessfully!</p> : ""}
          </div>
        </form>
      </div>
    </div>
  );
}