import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { db } from "../config/firebase";
import { collection, addDoc, doc, getDoc } from "firebase/firestore";
import './ContactUs.css';
import { Oval } from 'react-loader-spinner';
import { useAuth } from "../contexts/AuthContext";
import PageHeader from './PageHeaders';

const sendEmailURL = "https://us-central1-scootsafe-0001.cloudfunctions.net/sendEmail";

function ContactUs() {
  const [state, setState] = useState({
    message: '',
    fromEmail: '',
    reason: '',
    companyName: '',
    companyUrl: '',
    fName: '',
    lName: '',
    subject: '',
  });
  const { currentUser } = useAuth();
  let [changeText, setChangeText] = useState(true);
  let [showCompany, setShowCompany] = useState(false);
  const { message, fromEmail, fName, lName, subject, reason, companyName, companyUrl } = state;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const userRef = doc(db, "Users", currentUser.uid);
      getDoc(userRef).then((result) => {
        if (result.exists()) {
          setState({
            fromEmail: currentUser.email ? currentUser.email : '',
          });
        } else {
          setLoading(false);
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  }, [currentUser, state.fromEmail]);


  function handleReason({ target: { id, value } }) {
    setState({
      ...state, reason: value,
    });
    if (value === 'advertise' || value === 'scootsafeLocation') {
      setShowCompany(true);
    } else {
      setShowCompany(false);
    }

  }

  const resetValues = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      textarea => (textarea.value = "")
    );
    Array.from(document.querySelectorAll("select")).forEach(
      textarea => (textarea.value = "")
    );
    setState({
      message: '',
      fromEmail: '',
      companyName: '',
      companyUrl: '',
      fName: '',
      reason: '',
      lName: '',
      subject: '',
    });
  }

  const handleState = ({ target: { id, value } }) =>
    setState({ ...state, [id]: value });

  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();
    Axios.get(sendEmailURL, {
      params: {
        message,
        fromEmail,
        companyName,
        companyUrl,
        reason,
        fName,
        lName,
        subject,
      },
    }).then(function (response) {
      const emailRef = collection(db, "Emails");
      const data = {
        fromEmail: fromEmail,
        fName: fName,
        lName: lName,
        reason: reason,
      };
      addDoc(emailRef, data)
        .then(emailRef => {
          console.log("Document has been added successfully");
        })
        .catch(error => {
          console.log(error);
        })
      setLoading(false);
      resetValues();
      setChangeText(!changeText);
    });
  };

  return (
    <div>
      <div className="loader">
        <Oval
          height={80}
          width={80}
          color="#4fa94d"
          wrapperStyle={{}}
          wrapperClass=""
          visible={loading}
          ariaLabel='oval-loading'
          secondaryColor="#4fa94d"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        </div>
        <PageHeader
          title="Contact Us"
          subTitle="Please feel free to contact us with any question you may have."
          subSubTitle="We will be delighted to assist you with your inquiry."
        >
        </PageHeader>
        <div className="form-box">
          <form
            onSubmit={sendEmail}
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div class="grid-container-contact">
              <div class="grid-item fName">
                <input id={'fName'} defaultValue={fName} disabled={loading} type="text" onChange={handleState} value={fName} required placeholder="*First Name" />
              </div>
              <div class="grid-item lName">
                <input id={'lName'} defaultValue={lName} disabled={loading} type="text" onChange={handleState} value={lName} required placeholder="*Last Name" />
              </div>
              <div class="grid-item email">
                <input id={'fromEmail'} defaultValue={fromEmail} disabled={loading} type="email" onChange={handleState} value={fromEmail} required placeholder="*Email" />
              </div>
              <div class="grid-item reason">
                <select name="reason" id="reason" placeholder='Reason' required onChange={handleReason}>
                  <option value="" selected disabled hidden>*Reason</option>
                  <option value="inquiry">General Inquiries</option>
                  <option value="advertise">Advertise with Us</option>
                  <option value="scootsafeLocation">Get scootsafe in your place</option>
                  <option value="error">Error/Issue</option>
                </select>
              </div>
              {showCompany ?
                <>
                  <div class="grid-item companyName">
                    <input id={'companyName'} disabled={loading} type="text" onChange={handleState} value={companyName} required placeholder="*Company Name" />
                  </div>
                  <div class="grid-item companyUrl">
                    <input id={'companyUrl'} disabled={loading} type="text" onChange={handleState} value={companyUrl} required placeholder="*Company Url" />
                  </div>
                  <div class="grid-item subject"> <textarea
                    id={'subject'}
                    onChange={handleState}
                    value={subject}
                    disabled={loading}
                    placeholder="*Subject"
                    required
                    type="text"
                  /></div>
                  <div class="grid-item message"> <textarea
                    id={'message'}
                    onChange={handleState}
                    value={message}
                    disabled={loading}
                    placeholder="*Message"
                    required
                    type="text"
                  />
                    <p className='requiredness'>* is a required field</p>
                  </div>
                </>
                : <>
                  <div class="grid-item companyName"> <textarea
                    id={'subject'}
                    onChange={handleState}
                    value={subject}
                    disabled={loading}
                    placeholder="*Subject"
                    required
                    type="text"
                  /></div>
                  <div class="grid-item companyUrl"> <textarea
                    id={'message'}
                    onChange={handleState}
                    value={message}
                    disabled={loading}
                    placeholder="*Message"
                    required
                    type="text"
                  />
                    <p className='requiredness'>* is a required field</p>
                  </div>
                </>}
            </div>
            <button
              type="submit"
              disabled={loading}
              className="submitButton py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:ring-4 focus:outline-none"
            >
              Send Message
            </button>
            {changeText ? "" : <p className='successMessage'>Message sent sucessfully!</p>}
          </form>
        </div>
      </div>
      );
}

      export default ContactUs;