import { useState } from "react";
import { useAuth } from '../contexts/AuthContext';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ForgotPassword.css";
import PageHeader from './PageHeaders';
function ForgotPassword() {
    const navigate = useNavigate();
    const [state, setState] = useState({
        email: '',
        password: ''
    });

    const { email } = state;

    const handleState = ({ target: { id, value } }) =>
        setState({ ...state, [id]: value });

    const { currentUser, setError, passwordReset } = useAuth();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setError("");
        if (currentUser) {
            navigate("/");
        }
    }, [currentUser, navigate, setError]);

    async function handleFormSubmit(e) {
        e.preventDefault();
        try {
            setLoading(true);
            await passwordReset(email);
            setError("");
            navigate("/");
        } catch (e) {
            if (e.message.includes('wrong-password')) {
                setError("Password or Username is incorrect");
            }
            else if (e.message.includes('user-not-found')) {
                setError("Password or Username is incorrect");
            } else {
                setError("Failed to login " + e.message);
            }
        }

        setLoading(false);
    }
    return (
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <PageHeader
                        title="Forgot Password"
                        subTitle="">
                    </PageHeader>
                </div>
                <form className="mt-8 space-y-6"
                    onSubmit={handleFormSubmit}>
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <input
                                id={"email"}
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                onChange={handleState}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                                placeholder="Email address"
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            type="submit"
                            disabled={loading}
                            className=" w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white loginButton"
                        >
                            Send Reset Password
                        </button>
                    </div>
                    <p className="forgotText">Forgotten your email address or still can't get in? Having trouble? <a className="forgotLink" href="/contactUs">Contact us</a></p>
                </form>
            </div>
        </div>
    );
}
export default ForgotPassword;