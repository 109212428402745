import React from 'react';
import MapComponent from '../components/Map';
import instagramIcon from '../assets/instagramIconGreen.png';
import facebookIcon from '../assets/facebookIconGreen.png';
import youtubeIcon from '../assets/youtubeIconGreen.png';
import PageHeader from './PageHeaders';
function Locations() {
  const location = {
    address: 'Arthurs Quay Centre, Patrick St, Limerick',
    lat: 52.662543,
    lng: -8.625891,
  }
  return (
    <div>
      <div className="text-center">
        <PageHeader
          title="Locations"
          subTitle="Coming soon, follow us to get updates!">
        </PageHeader>
        <div className='mediaIconsContainer'>
          <a rel="noreferrer" target="_blank" href='https://www.instagram.com/scoot_safe/'><img alt='instagramIcon' className='mediaIcons' src={instagramIcon}></img></a>
          <a rel="noreferrer" target="_blank" href='https://www.facebook.com/scootsafestorage'><img alt='facebookIcon' className='mediaIcons' src={facebookIcon}></img></a>
          <a rel="noreferrer" target="_blank" href='https://www.youtube.com/channel/UCV4uq76W8voBy3Dllo3hVHQ'><img alt='mediaIcon' className='mediaIcons' src={youtubeIcon}></img></a>
        </div>
      </div>
      <MapComponent location={location} zoomLevel={14} />
    </div>
  );
}

export default Locations;