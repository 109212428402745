import React, { Component } from "react";
import HomeVideo from '../components/HomeVideo';
import {
  Box, Card
} from 'rebass';
import bike from '../assets/jsuScoot.png';
import padlock from '../assets/Padlock.png';
import location from '../assets/location.png';
import mapIcon from '../assets/mapIcon.png';
import mobileIcon from '../assets/mobileIcon.png';
import scootSafeIcon from '../assets/scootSafeSingle.png';
import scooterIcon from '../assets/scooterIcon.png';
import './HomeScreen.css';
class HomeScreen extends Component {
  render() {
    return (
      <div>
        <div className='fullPage'>
          <Card height='100%' minHeight='100%' sx={{
            p: 0,
          }}>
            <Box width={2 / 2} height="50%" px={2}>
              <HomeVideo />
            </Box>
            <hr />
            <h2 className='main-heading'>
              Your home for personal electric vehicle storage in Ireland
            </h2>
            <div className="bodyPage">
              <hr />
              <div class="grid-container">
                <div class="grid-item item1"><img alt="locationIcon" className="iconImage" src={location}></img></div>
                <div class="grid-item item2"><a id="menu-link" href="/location"><span className='scooterCardTitle'>Locations</span></a></div>
                <div class="grid-item item3"><span className='cardMessage'>Current and upcoming locations.</span></div>
                <div class="grid-item item4"><img alt="scooterIcon" className="iconImage" src={bike}></img></div>
                <div class="grid-item item5"><a id="menu-link" href="/aboutUs"><span className='scooterCardTitle'>About Us</span></a></div>
                <div class="grid-item item6"><span className='cardMessage'>Who we are and our goals.</span></div>
                <div class="grid-item item7"><img alt="padlockIcon" className="iconImage" src={padlock}></img></div>
                <div class="grid-item item8"><a id="menu-link" href="/renting"><span className='scooterCardTitle'>Renting</span></a></div>
                <div class="grid-item item9"><span className='cardMessage'>Instructions and pricing.</span></div>
              </div>
            <hr />
            </div>
            <div class="grid-container-maincontent">
              <div class="grid-item-content mainContent1"><img alt="mapIcon" className='mapIconStyle' src={mapIcon}></img></div>
              <div class="grid-item-content mainContent2"><p className='scooterContentTitle'>Check our locations</p><p className='scooterContentTitleAlternative'>Find a near by space</p><p className="scooterContentSubtitle">Locations available on ScootSafe app and website!</p></div>
              <div class="grid-item-content mainContent3"><p className='scooterContentTitleAlternative'>Check Availability</p><p className='scooterContentTitle'>Go to available locker</p><p className="scooterContentSubtitle">You can check locker availabity on the ScootSafe app!</p></div>
              <div class="grid-item-content mainContent4"><img alt="scootsafeIcon" className='scootSafeStyle' src={scootSafeIcon}></img></div>
              <div class="grid-item-content mainContent6"><p className='scooterContentTitle'>Open ScootSafe App</p><p className='scooterContentTitleAlternative'>Book your locker</p><p className="scooterContentSubtitle">You're ready to unlock the locker!</p></div>
              <div class="grid-item-content mainContent5"><img alt="appIcon" className='mobileIconStyle' src={mobileIcon}></img></div>
              <div class="grid-item-content mainContent7"><p className='scooterContentTitleAlternative'>Open Locker</p><p className='scooterContentTitle'>Store your scooter</p><p className="scooterContentSubtitle">Only you will be able to unlock the locker!</p></div>
              <div class="grid-item-content mainContent8"><img alt="scooterIcon" className='scooterIconStyle' src={scooterIcon}></img></div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default HomeScreen;