import React from 'react';
import instagramIcon from '../assets/instagramIconGreen.png';
import facebookIcon from '../assets/facebookIconGreen.png';
import youtubeIcon from '../assets/youtubeIconGreen.png';
import { InstagramEmbed, YouTubeEmbed } from 'react-social-media-embed';
import './Media.css'
import PageHeader from './PageHeaders';
function Media() {
  return (
    <div>
      <div className="text-center">
        <PageHeader
          title="Media"
          subTitle="Follow us for more updates on our app, locations, e-scooter news, contests, trails, and much more...">
        </PageHeader>
        <div className='mediaIconsContainer'>
          <a rel="noreferrer" target="_blank" href='https://www.instagram.com/scoot_safe/'><img alt='instagramIcon' className='mediaIcons' src={instagramIcon}></img></a>
          <a rel="noreferrer" target="_blank" href='https://www.facebook.com/scootsafestorage'><img alt='facebookIcon' className='mediaIcons' src={facebookIcon}></img></a>
          <a rel="noreferrer" target="_blank" href='https://www.youtube.com/channel/UCV4uq76W8voBy3Dllo3hVHQ'><img alt='mediaIcon' className='mediaIcons' src={youtubeIcon}></img></a>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <InstagramEmbed url="https://www.instagram.com/p/CifoCe4qOcV/" width={328} />
      </div>
      <div class="grid-container-media">
        <div class="grid-item media1"><YouTubeEmbed url="https://www.youtube.com/watch?v=BBeA0JIsmpc" width="100%" height="100%" /></div>
        <div class="grid-item media2"><YouTubeEmbed url="https://www.youtube.com/watch?v=rrodXWqEJSg" width="100%" height="100%" /></div>
        <div class="grid-item media3"><YouTubeEmbed url="https://www.youtube.com/watch?v=QNSqRUjqMts" width="100%" height="100%" /></div>
        <div class="grid-item media4"><YouTubeEmbed url="https://www.youtube.com/watch?v=UaGSyuxJSO4" width="100%" height="100%" /></div>
        <div class="grid-item media5"><YouTubeEmbed url="https://www.youtube.com/watch?v=cX7K1tEE9_8" width="100%" height="100%" /></div>
        <div class="grid-item media6"><YouTubeEmbed url="https://www.youtube.com/watch?v=yHsKlYvGxdA" width="100%" height="100%" /></div>
      </div>
    </div>
  );
}

export default Media;