import React from 'react';
import './Footer.css';
import instagramIcon from '../assets/instagramIconGrey.png';
import facebookIcon from '../assets/facebookIconGrey.png';
import youtubeIcon from '../assets/youtubeIconGrey.png';
import googlePlay from '../assets/googlePlay.png';
import ios from '../assets/ios.png';


function Footer() {
    return (
        <div className='footer'>
            <div class="grid-container-footer">
                <div class="grid-item itemA"><span className='titleFooter'>ScootSafe</span></div>
                <div class="grid-item itemB"><a className='subTitleFooterUrl' href='/aboutUs'><span className='subTitleFooterUrl'>About Us</span></a></div>
                <div class="grid-item itemC"><a className='subTitleFooterUrl' href='/contactUs'><span className='subTitleFooterUrl'>Contact Us</span></a></div>
                <div class="grid-item itemJ"><a className='subTitleFooterUrl' without rel="noreferrer" target="_blank" href='https://www.surveymonkey.com/r/Z5TVSBT'><span className='subTitleFooterUrl'>Survey</span></a></div>
                <div class="grid-item itemD"><span className='titleFooter'>Follow Us</span></div>
                <div class="grid-item itemE"><img alt="instaIcon" className='socialMediaIcon' src={instagramIcon}></img><a className='subTitleFooterUrl' rel="noreferrer" target="_blank" href='https://www.instagram.com/scoot_safe/'><span className='subTitleFooterUrl'>Instagram</span></a></div>
                <div class="grid-item itemF"><img alt="facebookIcon" className='socialMediaIcon' src={facebookIcon}></img><a className='subTitleFooterUrl' rel="noreferrer" target="_blank" href='https://www.facebook.com/scootsafestorage'><span className='subTitleFooterUrl'>Facebook</span></a></div>
                <div class="grid-item itemG"><img alt="youtubeIcon" className='socialMediaIcon' src={youtubeIcon}></img><a className='subTitleFooterUrl' rel="noreferrer" target="_blank" href='https://www.youtube.com/channel/UCV4uq76W8voBy3Dllo3hVHQ'><span className='subTitleFooterUrl'>YouTube</span></a></div>
                <div class="grid-item itemH"><span className='titleFooter'>Download the App</span></div>
                <div className="grid-item itemI">
                    <a href="https://play.google.com/store/apps/details?id=com.scootsafe" target="_blank" rel="noreferrer">
                    <img alt="googlePlay" className='socialMediaIcon' src={googlePlay}></img><span className='subTitleFooterUrl'>Android</span>
                    </a>
                    <br />
                    <a href="https://apps.apple.com/us/app/scootsafe/id6449438300" target="_blank" rel="noreferrer">
                    <img alt="ios" className='socialMediaIcon' src={ios}></img><span className='subTitleFooterUrl'>iOS</span> 
                    </a>
                </div>
            </div>
            <div className='subFooter'>
                <div class="grid-container-subfooter">
                    <div class="grid-item itemA"><span className='titlesubFooter'><a href='/termsAndConditions'>Terms and Conditions</a></span></div>
                    <div class="grid-item itemD"><span className='titlesubFooter'><a without rel="noreferrer" target="_blank" href="https://www.iubenda.com/privacy-policy/78280850" title="Privacy Policy ">Privacy Policy</a></span></div>
                    <div class="grid-item itemH"><span className='titlesubFooter'>ScootSafe 2023 ©</span></div>
                </div>
            </div>
        </div>
    );
}

export default Footer;