import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from "react-router-dom";
import './LoginMenu.css';

function LoginMenu() {
  const { logout, setError, currentUser, auth } = useAuth();
  const navigate = useNavigate();
  const [showDropDown, setShowModal] = useState(false);
  const [userDetail, setUserDetail] = useState(currentUser);

  const ref = useRef(null);

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged((user) => {
      if (!user) {
        setUserDetail(null);
      } else {
        setUserDetail({ ...user });
      }
    });

    return unsubscribe;
  }, [auth]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);


  async function handleLoginDirective() {
    try {
      setError("");
      navigate("/login");
    } catch (error) {
      setError("Failed to navigate");
    }
  }

  async function handleRegisterDirective() {
    try {
      setError("");
      navigate("/register");
    } catch (error) {
      setError("Failed to navigate");
    }
  }

  function showDialog() {
    setShowModal(!showDropDown);
  }

  async function handleLogout() {
    try {
      setError("");
      await logout();
      navigate("/");
    } catch (error) {
      setError("Failed to logout");
    }
  }
  return (
    <div>
      {userDetail ?
        <div>

          <div className='profileContainer'>
            {userDetail.displayName ? <p className='userDisplay'>Hello, {userDetail.displayName}</p> :
              userDetail.providerData ? userDetail.providerData.length ? userDetail.providerData[0].displayName && !userDetail.displayName ? <p className='userDisplay'>Hello, {userDetail.providerData[0].displayName}</p> : "" : "" : ""}
            {userDetail.photoURL ?
              <div class="dropdown">
                <img
                  className="h-8 w-8 rounded-full profileIconLink"
                  src={userDetail.photoURL}
                  alt=""
                  onClick={showDialog}
                /></div> : <div class="dropdown"><button
                  type="button"
                  className='underLoginText hover:underline profileIconLink'
                  onClick={showDialog}
                >
                  My Profile
                </button></div>}

            {showDropDown ? <div ref={ref} id="myDropdown" class="dropdown-content">
              <br />
              <a className="dropDownItem" href="/MyProfile">My Profile</a>
              <br />
              <button
                className="dropDownItem"
                type="button"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div> : ""}
          </div>
        </div>
        :
        <div><button
          type="button"
          className="profileIconLink underLoginText hover:underline "
          onClick={handleLoginDirective}
        >
          Login
        </button>
          <span className='seperater'>&nbsp;|&nbsp;</span>
          <button
            className="profileIconLink underLoginText hover:underline "
            type="button"
            onClick={handleRegisterDirective}
          >
            Register
          </button>
        </div>
      }

    </div>
  );
}

export default LoginMenu;