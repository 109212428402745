import React from 'react';
import specLayout from '../assets/layoutspecs.png';
import model from '../assets/model5.png';
import './AboutUs.css';
import PageHeader from './PageHeaders';
function AboutUs() {
  return (
    <div>
      <div>
      <PageHeader 
      title="About Us" 
      subTitle="Who we are and what we do at ScootSafe">
      </PageHeader>
        <div className='aboutContent'>
          <h3 className="aboutTitle">Who we are</h3>
          <p>Welcome to ScootSafe. We are a locally owned and operated startup, dedicated to providing a safe and convenient way for electric scooter users to store their scooters.</p>
          <p>At ScootSafe, we understand the importance of electric scooters in modern transportation. They are efficient, eco-friendly, and a fun way to get around. However, we also understand that storing electric scooters can be a hassle. This is true for those navigating the city, accessing local amenities and in general for those going about their daily lives. There is a notable absence of secure storage facilities for scooter-users.</p>
          <br />
          <p>That's why we created ScootSafe, a secure and affordable electric scooter storage solution. Our lockers have been designed to ensure that your electric scooter is safe and secure at all times.</p>
          <p>At ScootSafe, we are committed to providing exceptional value for our customers. Our team of professionals is always available to assist you with any questions or concerns you may have.</p>
          <p>Feel free to send us a message via our <a className='link' href="/contactUs">Contact Us</a> form.</p>
          <br />
          <p>We believe that electric scooters are the future of transportation, and we are proud to be a part of the movement. Join us today to experience the convenience and peace of mind that comes with ScootSafe electric scooter storage.</p>
          <p className='link'>#ScootSafe #SecureStorage #EasyCommuting</p>
        </div>
        <div className='aboutContent'>
          <h3 className="aboutTitle">How it works</h3>
          <p>Welcome to our website, where we detail our convenient and secure solution for storing your electric scooter. Our storage lockers are designed to accommodate all common e-scooter brands in addition to your safety gear, providing a safe and reliable way to keep your valuable equipment protected.</p>
          <br />
          <p>Using our storage lockers is simple - download our app on your IOS or Android device and use it to locate the nearest available locker. Once you arrive at the locker, simply use the app to unlock the locker and store your scooter and any additional gear. The locker will remain locked and secure until you return to retrieve your equipment.</p>
          <br />
          <p>We take pride in providing a high-quality storage solution for electric scooters, making it easy for you to transport and store your equipment without the hassle of carrying it with you everywhere you go. Our mission is to make your life easier, and we believe that our storage lockers are an essential tool for anyone who owns an electric scooter.</p>
          <br />
          <p>Check out our locations and get started today on our app or here at <a className="link" href="/location">View Locations</a></p>
          <br />
          <p>Thank you for choosing ScootSafe.</p>
        </div>
        <div className='aboutContent'>
          <h3 className="aboutTitle">Locker Specifications</h3>
          <div className="imageContainer">
            <img alt="specs" className='aboutUsImages' src={specLayout}></img>
            <img alt="model" className='aboutUsImages' src={model}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;