import homeVid from '../assets/homeVid.mp4';
import homeVidThumb from '../assets/videoThumbnail.png';
import './HomeVideo.css';
const HomeVideo = () => {
  return (
    <div id="main-container">
      <video playInline autoPlay muted id="main-video" poster={homeVidThumb}>
        <source src={homeVid} type="video/mp4"
        />
        Sorry, your browser doesn't support videos.
      </video>
    </div>
  );
};

export default HomeVideo;