import { Link } from "react-router-dom";
import { useState } from "react";
import { useAuth } from '../contexts/AuthContext';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleAuthProvider } from "firebase/auth";
import googleIcon from '../assets/googleIcon.png';
import PageHeader from './PageHeaders';
import './LoginScreen.css';
function LoginScreen() {
    const navigate = useNavigate();
    const [state, setState] = useState({
        email: '',
        password: ''
    });

    const { email, password } = state;

    const handleState = ({ target: { id, value } }) =>
        setState({ ...state, [id]: value });

    const { currentUser, login, setError, signInWithGoogle } = useAuth();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (currentUser) {
            setError("");
            navigate("/");
        }
    }, [currentUser, navigate]);

    async function handleFormSubmit(e) {
        e.preventDefault();
        try {
            setLoading(true);
            await login(email, password);
            setError("");
            navigate("/");
        } catch (e) {
            if (e.message.includes('wrong-password')) {
                setError("Password or Username is incorrect");
            }
            else if (e.message.includes('user-not-found')) {
                setError("Password or Username is incorrect");
            } else {
                setError("Failed to login " + e.message);
            }
        }

        setLoading(false);
    }

    async function handleGoogleSignIn() {
        try {
            setError("");
            setLoading(true);
            signInWithGoogle()
                .then((result) => {
                    GoogleAuthProvider.credentialFromResult(result);
                    navigate("/");
                }).catch((error) => {
                    const errorMessage = error.message;
                    setError(errorMessage);
                });

        } catch (e) {
            if (e.message.includes('wrong-password')) {
                setError("Password or Username is incorrect");
            }
            else if (e.message.includes('user-not-found')) {
                setError("Password or Username is incorrect");
            } else {
                setError("Failed to login " + e.message);
            }
        }

        setLoading(false);
    }

    return (
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <PageHeader
                        title="Login to your account"
                        subTitle="">
                    </PageHeader>
                </div>
                <form className="mt-8 space-y-6"
                    onSubmit={handleFormSubmit}>
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <input
                                id={"email"}
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                onChange={handleState}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                                placeholder="Email address"
                            />
                        </div>
                        <br />
                        <div>
                            <input
                                id={"password"}
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                onChange={handleState}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                                placeholder="Password"
                            />
                        </div>
                    </div>
                    <a href='/forgotPassword' className="text-sm underLoginText hover:underline">
                        Forgot password?
                    </a>
                    <div>
                        <button
                            type="submit"
                            disabled={loading}
                            className=" w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white loginButton"
                        >
                            Login
                        </button>
                    </div>
                    <div>
                        <div className="text-sm">
                            <button
                                onClick={handleGoogleSignIn}
                                disabled={loading}
                                className=" w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white googleButton"
                            >
                                Sign In With Google <img alt="googleIcon" src={googleIcon} className="googleIcon"></img>
                            </button>
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="text-sm">
                            <Link
                                to="/register"
                                className="underLoginText hover:underline "
                            >
                                Don't have an account? Register
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
export default LoginScreen;