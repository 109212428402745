import React from 'react';
import PageHeader from './PageHeaders';
import './Renting.css';
import instagramIcon from '../assets/instagramIconGreen.png';
import facebookIcon from '../assets/facebookIconGreen.png';
import youtubeIcon from '../assets/youtubeIconGreen.png';
function Renting() {
  return (
    <div>
      <div className="text-center">
        <PageHeader
          title="Renting"
          subTitle="Download our app on IOS and Anroid to use ScootSafe features!">
        </PageHeader>
      </div>
      <div className="container">
      <h1 className="title">How to Use the App</h1>
      <h2 className="step">1. Find Nearest Locker</h2>
      <p className="text">
        Use our locker locations to find the nearest available locker to you.
      </p>
      <h2 className="step">2. Scan QR Code</h2>
      <p className="text">
        To rent a locker, find an available locker and scan the QR code on it.
      </p>
      <h2 className="step">3. Rent a Locker</h2>
      <p className="text">
        After scanning the QR code, confirm to rent the locker. The rental period will begin, and you will be charged on an hourly basis.
      </p>
      <h2 className="step">4. Unlock the Locker</h2>
      <p className="text">
        When you're ready to unlock the locker, navigate to the Unlock tab and follow the instructions to complete the process.
      </p>
      <h2 className="step">5. Payment and Rates</h2>
      <p className="text">
        You will be charged €1 for the first half hour, with a minimum spend of €1. The rates for subsequent hours on the hour are as follows:
      </p>
      <p className="text">
        - 30 min: €1
      </p>
      <p className="text">
        - 1 hour: €2
      </p>
      <p className="text">
        - 2 hours: €3
      </p>
      <p className="text">
        - 4 hours: €5
      </p>
      <p className="text">
        - 8 hours: €9
      </p>
      <h2 className="step">6. Additional Features</h2>
      <p className="text">
        View current lockers in uses in Locker Status, edit your profile in the Profile section to choose your scooter preferences.
      </p>
    </div>
    </div>
  );
}

export default Renting;