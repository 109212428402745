import React from 'react'
import './PageHeaders.css'
const PageHeader = ({ title, subTitle, subSubTitle }) => (
    <div>
        <h2 className='heading'>
            {title}
        </h2>
        <h3 className='subHeading'>
            {subTitle}
        </h3>
        <h3 className='subSubHeading'>
            {subSubTitle}
        </h3>
    </div>
);
export default PageHeader